<template>
  <div class="uses--each__container">
    <div class="uses--each--content py-6 flex flex-wrap">
      <div class="left--details md:w-1/3 w-full">
        <h1 class="text-xl">
          <span class="font-medium">{{ thing.type }}</span>
          /
          <span class="text-gray-600">{{ thing.subCategory }}</span>
        </h1>
      </div>
      <div class="right--details md:w-2/3 md:mt-0 mt-6">
        <h1 class="name text-2xl font-bold">{{ thing.name }}</h1>
        <h3 class="desc mt-2 text-lg text-gray-700">
          {{ thing.spec }}
        </h3>
        <p v-if="thing.text" class="mt-4 text-gray-600">{{ thing.text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UsesEach",
  props: {
    thing: {
      type: Object,
      default: null,
      require: true,
    },
  },
};
</script>
