import { render, staticRenderFns } from "./Uses.vue?vue&type=template&id=006953a5&"
import script from "./Uses.vue?vue&type=script&lang=js&"
export * from "./Uses.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports